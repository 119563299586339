import React from 'react';
import { Link } from 'react-router-dom';
import ModalImage from "react-modal-image";


const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
    <ModalImage
  small={`${PUBLIC_URL}/images/me.png`}
  large={`${PUBLIC_URL}/images/archanafull.png`}
  alt="Hello World!"
/>
    {/*   <Link to="/" >
        <img src={`${PUBLIC_URL}/images/me.png`} alt="" />
      </Link> */}
      <header>
        <h2>Archana Morye</h2>
        <p>Phone Number : +91 40 2312 4211</p>
        <p>Email Id : <a href="mailto:asmsm@uohyd.ac.in">asmsm@uohyd.ac.in</a></p>
      </header>
    </section>

    

    <section id="footer">
     
      <p className="copyright">&copy;  <Link to="/">archanamorye.com</Link></p>
    </section>
  </section>
);

export default SideBar;
