import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const OtherInterest = lazy(() => import('./pages/OtherInterest'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Publications = lazy(() => import('./pages/Publications'));
const Conferences = lazy(() => import('./pages/Conferences'));
const OutReach = lazy(() => import('./pages/OutReach'));

const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    <Suspense fallback={<Main />}>
      <Routes>
        <Route exact path="/" element={<About/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/publications" element={<Publications/>} />
        <Route path="/outreach" element={<OutReach/>} />
         <Route path="/conferences" element={<Conferences/>} />
        <Route element={NotFound} status={404} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
