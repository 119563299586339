const routes = [
  {
    index: true,
    label: "Archana Morye",
    path: '/',
  },
  {
    label: 'About Me',
    path: '/about',
  },
  {
    label: 'Research',
    path: '/publications',
  },
  {
    label: 'Workshops & Conference',
    path: '/conferences',
  },
 
  {
    label: 'Outreach',
    path: '/outreach',
  }
];

export default routes;

