import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';
import ReactDOM from "react-dom/client";
// See https://reactjs.org/docs/strict-mode.html
const StrictApp = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

// hydrate is required by react-snap.
//if (rootElement.hasChildNodes()) {
  //hydrate(<StrictApp />, rootElement);
//} else {
  root.render(<StrictApp />);
//}
